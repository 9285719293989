import React from "react"
import { Link } from "gatsby"

import MTholiday from '../assets/project-xmas-card.png';
import MTposter from '../assets/MT-mission.png';
import MTvalues1 from '../assets/MT-vision1.png';
import MTvalues2 from '../assets/MT-vision2.png';
import MTvalues3 from '../assets/MT-vision3.png';
import stickerDedication from '../assets/sticker-dedication.svg';
import stickerHonesty from '../assets/sticker-honesty.svg';
import stickerCommunication from '../assets/sticker-communication.svg';
import stickerInnovation from '../assets/sticker-fists.svg';
import stickerData from '../assets/sticker-data.svg';
import stickerDevotion from '../assets/sticker-devotion.svg';
import stickerRespect from '../assets/sticker-respect.svg';
import stickerCollaboration from '../assets/sticker-collab.svg';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img className="col-10 margin-btm-lg col-100" src={MTholiday} alt="Mariana Tek Site Design" />
            <div className="align-left margin-top-neg">
              <h1 className="header--medium margin-btm-xxs">Mariana Tek - Illustrations</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>I created an illustrative style for Mariana Tek and used that style to create assets for internal and external use. Internally, I worked with our Exec and HR teams to create mission and values posters and stickers to inspire and remind the team what Mariana Tek is aiming to accomplish. Externally, I created assets such as holiday cards, adding illustration for a touch of whimsy, appropriate for the occasion.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Director of Design</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Full Design</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Printing</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Direction and Coordination</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-10 img-center col-100">
            <p className="align-center margin-btm-sm">Sample of mission and values (posters plus stickers)</p>
            <div className="row-projects">
              <div className="column column1 row-69 row-padding margin-btm-lg">
                <div className="project">
                  <img className="project-with-border withShadow" src={MTposter} alt="Mariana Tek Mission poster" />
                </div>
              </div>
              <div className="column column2 row-31 margin-btm-lg">
                <div className="project project-mobile-sm-mar">
                  <img className="project-with-border withShadow" src={MTvalues1} alt="Mariana Tek values poster 1" />
                </div>
                <div className="project project-mobile-sm-mar">
                  <img className="project-with-border withShadow" src={MTvalues2} alt="Mariana Tek values poster 2" />
                </div>
                <div className="project project-mobile-sm-mar">
                  <img className="project-with-border withShadow" src={MTvalues3} alt="Mariana Tek values poster 3" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-grid flex-grid-4">
            <div className="project sticker">
              <img src={stickerDedication} alt="Mariana Tek's values sticker dedication" />
            </div>
            <div className="project sticker">
              <img src={stickerHonesty} alt="Mariana Tek's values sticker honesty" />
            </div>
            <div className="project sticker-sm">
              <img src={stickerCommunication} alt="Mariana Tek's values sticker commuication" />
            </div>
            <div className="project sticker-sm">
              <img src={stickerInnovation} alt="Mariana Tek's values sticker innovation" />
            </div>
            <div className="project sticker">
              <img src={stickerData} alt="Mariana Tek's values sticker data driven mindset" />
            </div>
            <div className="project sticker">
              <img src={stickerDevotion} alt="Mariana Tek's values sticker devotion" />
            </div>
            <div className="project sticker">
              <img src={stickerRespect} alt="Mariana Tek's values sticker respect" />
            </div>
            <div className="project sticker">
              <img src={stickerCollaboration} alt="Mariana Tek's values sticker collaboration" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-admin">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/MT-admin/">
                  <h3 className="header--medium">Mariana Tek - Admin / Developer Portal</h3>
                </Link>
                <span className="description">Design, UX, Prototyping, Testing</span>
              </div>
              <Link to="/MT-admin/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
